.ReactTable {
  border: 1px solid #0000001a;
  flex-direction: column;
  display: flex;
  position: relative;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  border-collapse: collapse;
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  width: 100%;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-thead {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
}

.ReactTable .rt-thead.-headerGroups {
  background: #00000008;
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  font-size: inherit;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline: none;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid #00000005;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px #00000026;
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: 1px solid #0000000d;
  padding: 5px;
  line-height: normal;
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  position: relative;
  box-shadow: inset 0 0 #0000;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px #0009;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px #0009;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 100%;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-width: 8px;
  border-color: #fff0 #fff0 #fff0 #fff;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-width: 10px;
  border-color: #6660 #6660 #6660 #f7f7f7;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex-direction: column;
  flex: 99999 auto;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #00000005;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex-direction: column;
  flex: 1 0 auto;
  align-items: stretch;
  display: flex;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 0;
  padding: 7px 5px;
  transition: width .3s, min-width .3s, padding .3s, opacity .3s;
  overflow: hidden;
}

.ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
  opacity: 0 !important;
  border: 0 !important;
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
}

.ReactTable .rt-expander {
  color: #0000;
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.ReactTable .rt-expander:after {
  content: "";
  cursor: pointer;
  border-top: 7px solid #000c;
  border-left: 5.04px solid #0000;
  border-right: 5.04px solid #0000;
  width: 0;
  height: 0;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-90deg);
}

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%)rotate(0);
}

.ReactTable .rt-resizer {
  cursor: col-resize;
  z-index: 10;
  width: 36px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

.ReactTable .rt-tfoot {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
  box-shadow: 0 0 15px #00000026;
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid #0000000d;
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: #00000008;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #0000000d;
}

.ReactTable .-pagination {
  z-index: 1;
  border-top: 2px solid #0000001a;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 3px;
  display: flex;
  box-shadow: 0 0 15px #0000001a;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  font-size: inherit;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline: none;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .-pagination .-btn {
  appearance: none;
  color: #0009;
  cursor: pointer;
  background: #0000001a;
  border: 0;
  border-radius: 3px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 6px;
  font-size: 1em;
  transition: all .1s;
  display: block;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  color: #fff;
  background: #0000004d;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  text-align: center;
  flex: 1;
}

.ReactTable .-pagination .-center {
  text-align: center;
  flex-flow: wrap;
  flex: 1.5;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.ReactTable .-pagination .-pageInfo {
  white-space: nowrap;
  margin: 3px 10px;
  display: inline-block;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  text-align: center;
  width: 70px;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  z-index: 1;
  pointer-events: none;
  color: #00000080;
  background: #fffc;
  padding: 20px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactTable .-loading {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  background: #fffc;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 0;
}

.ReactTable .-loading > div {
  text-align: center;
  color: #0009;
  width: 100%;
  font-size: 15px;
  transition: all .3s cubic-bezier(.25, .46, .45, .94);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-52%);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
  transition: none !important;
}

.rc-tooltip.rc-tooltip-zoom-enter, .rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter, .rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.18, .89, .32, 1.28);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-leave {
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.6, -.3, .74, .05);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active, .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0);
  }
}

.rc-tooltip {
  z-index: 1070;
  visibility: visible;
  opacity: .9;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  position: absolute;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top, .rc-tooltip-placement-topLeft, .rc-tooltip-placement-topRight {
  padding: 5px 0 9px;
}

.rc-tooltip-placement-right, .rc-tooltip-placement-rightTop, .rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom, .rc-tooltip-placement-bottomLeft, .rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px;
}

.rc-tooltip-placement-left, .rc-tooltip-placement-leftTop, .rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  color: #fff;
  text-align: left;
  background-color: #373737;
  border-radius: 6px;
  min-height: 34px;
  padding: 8px 10px;
  text-decoration: none;
  box-shadow: 0 0 4px #0000002b;
}

.rc-tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #373737;
  margin-left: -5px;
  bottom: 4px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow, .rc-tooltip-placement-rightTop .rc-tooltip-arrow, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
  margin-top: -5px;
  left: 4px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow, .rc-tooltip-placement-leftTop .rc-tooltip-arrow, .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
  margin-top: -5px;
  right: 4px;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
  margin-left: -5px;
  top: 4px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  flex-direction: column;
  display: inline-flex;
}

.rdrDateDisplay {
  justify-content: space-between;
  display: flex;
}

.rdrDateDisplayItem {
  text-align: center;
  color: inherit;
  flex: 1;
  width: 0;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: .833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  justify-content: space-between;
  display: flex;
}

.rdrMonthAndYearPickers {
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  flex-direction: row;
  display: flex;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  box-sizing: inherit;
  text-align: center;
  flex-basis: 14.2857%;
}

.rdrDays {
  flex-wrap: wrap;
  display: flex;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  color: red;
  font-size: 1.6em;
  line-height: 1.6em;
  position: absolute;
  top: 0;
  right: .25em;
}

.rdrDay {
  box-sizing: inherit;
  font: inherit;
  cursor: pointer;
  width: 14.2857%;
  position: relative;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
}

.rdrStaticRanges {
  flex-direction: column;
  display: flex;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}

.rdrCalendarWrapper {
  color: #000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: #eff2f7;
}

.rdrDateDisplay {
  margin: .833em;
}

.rdrDateDisplayItem {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  box-shadow: 0 1px 2px #23394236;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  color: #849095;
  background: none;
  border: 0;
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  appearance: none;
  color: #3e484f;
  cursor: pointer;
  text-align: center;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") right 8px center no-repeat;
  border: 0;
  border-radius: 4px;
  outline: 0;
  padding: 10px 30px 10px 10px;
}

.rdrMonthAndYearPickers select:hover {
  background-color: #00000012;
}

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  background: #eff2f7;
  border: 0;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin: 0 .833em;
  padding: 0;
  display: block;
}

.rdrNextPrevButton:hover {
  background: #e1e7f0;
}

.rdrNextPrevButton i {
  text-align: center;
  border-style: solid;
  width: 0;
  height: 0;
  margin: auto;
  padding: 0;
  display: block;
  transform: translate(-3px);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: #0000 #34495e #0000 #0000;
  transform: translate(-3px);
}

.rdrNextButton i {
  border-width: 4px 4px 4px 6px;
  border-color: #0000 #0000 #0000 #34495e;
  margin: 0 0 0 7px;
  transform: translate(3px);
}

.rdrWeekDays {
  padding: 0 .833em;
}

.rdrMonth {
  padding: 0 .833em 1.666em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  color: #849095;
  font-weight: 400;
  line-height: 2.667em;
}

.rdrDay {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  color: #1d2429;
  background: none;
  border: 0;
  height: 3em;
  padding: 0;
  line-height: 3em;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  display: flex;
  position: absolute;
  inset: 5px 0;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: "";
  background: #3d91ff;
  border-radius: 2px;
  width: 18px;
  height: 2px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #ffffffd9;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  inset: 5px 0;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected {
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
  z-index: 1;
  background: #ffffff17;
  border: 0 solid;
  position: absolute;
  inset: 3px 0;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDefinedRangesWrapper {
  background: #fff;
  border-right: 1px solid #eff2f7;
  width: 226px;
  font-size: 12px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600;
}

.rdrStaticRange {
  cursor: pointer;
  background: #fff;
  border: 0;
  border-bottom: 1px solid #eff2f7;
  outline: 0;
  padding: 0;
  display: block;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  text-align: left;
  outline: 0;
  padding: 10px 20px;
  line-height: 18px;
  display: block;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  text-align: center;
  color: #6c767a;
  border: 1px solid #dee7eb;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  line-height: 30px;
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
  color: #333;
  border-color: #b4bfc4;
  outline: 0;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  background: none;
  border: 1px solid;
  border-radius: 1.333em;
  position: absolute;
  inset: -2px 0;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: #f8f8f8;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale() opacity(60%);
}

.rdrMonthName {
  text-align: left;
  color: #849095;
  padding: .833em;
  font-weight: 600;
}

.jsoneditor input, .jsoneditor input:not([type]), .jsoneditor input[type="text"], .jsoneditor input[type="search"], .jsoneditor-modal input, .jsoneditor-modal input:not([type]), .jsoneditor-modal input[type="text"], .jsoneditor-modal input[type="search"] {
  border: inherit;
  box-shadow: none;
  font-size: inherit;
  box-sizing: inherit;
  padding: inherit;
  font-family: inherit;
  line-height: inherit;
  height: auto;
  transition: none;
}

.jsoneditor input:focus, .jsoneditor input:not([type]):focus, .jsoneditor input[type="text"]:focus, .jsoneditor input[type="search"]:focus, .jsoneditor-modal input:focus, .jsoneditor-modal input:not([type]):focus, .jsoneditor-modal input[type="text"]:focus, .jsoneditor-modal input[type="search"]:focus {
  border: inherit;
  box-shadow: inherit;
}

.jsoneditor textarea, .jsoneditor-modal textarea {
  height: inherit;
}

.jsoneditor select, .jsoneditor-modal select {
  display: inherit;
  height: inherit;
}

.jsoneditor label, .jsoneditor-modal label {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.jsoneditor table, .jsoneditor-modal table {
  border-collapse: collapse;
  width: auto;
}

.jsoneditor td, .jsoneditor th, .jsoneditor-modal td, .jsoneditor-modal th {
  text-align: left;
  vertical-align: inherit;
  border-radius: inherit;
  padding: 0;
  display: table-cell;
}

.jsoneditor .autocomplete.dropdown {
  cursor: default;
  text-align: left;
  background: #fff;
  border: 1px solid #d3d3d3;
  outline: 0;
  margin: 0;
  padding: 5px;
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
  font-size: 14px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 2px 2px 12px #8080804d;
}

.jsoneditor .autocomplete.dropdown .item {
  color: #1a1a1a;
}

.jsoneditor .autocomplete.dropdown .item.hover {
  background-color: #ebebeb;
}

.jsoneditor .autocomplete.hint {
  color: #a1a1a1;
  top: 4px;
  left: 4px;
}

.jsoneditor-contextmenu-root {
  width: 0;
  height: 0;
  position: relative;
}

.jsoneditor-contextmenu {
  box-sizing: content-box;
  z-index: 2;
  position: absolute;
}

.jsoneditor-contextmenu .jsoneditor-menu {
  background: #fff;
  border: 1px solid #d3d3d3;
  width: 128px;
  height: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 12px #8080804d;
}

.jsoneditor-contextmenu .jsoneditor-menu button {
  cursor: pointer;
  color: #4d4d4d;
  box-sizing: border-box;
  text-align: left;
  background: none;
  border: none;
  width: 128px;
  height: auto;
  margin: 0;
  padding: 0 8px 0 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  position: relative;
}

.jsoneditor-contextmenu .jsoneditor-menu button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-default {
  width: 96px;
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-expand {
  float: right;
  border-left: 1px solid #e5e5e5;
  width: 32px;
  height: 24px;
}

.jsoneditor-contextmenu .jsoneditor-menu li {
  overflow: hidden;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul {
  -o-transition: all .3s ease-out;
  border: none;
  padding: 0 10px;
  transition: all .3s ease-out;
  display: none;
  position: relative;
  top: 0;
  left: -10px;
  box-shadow: inset 0 0 10px #80808080;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul .jsoneditor-icon {
  margin-left: 24px;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button {
  padding-left: 24px;
  animation: 1s ease-in-out all;
}

.jsoneditor-contextmenu .jsoneditor-menu li button .jsoneditor-expand {
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: 0 -72px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.jsoneditor-contextmenu .jsoneditor-icon {
  background-image: url("jsoneditor-icons.912c84a2.svg");
  border: none;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.jsoneditor-contextmenu .jsoneditor-text {
  word-wrap: break-word;
  padding: 4px 0 4px 24px;
}

.jsoneditor-contextmenu .jsoneditor-text.jsoneditor-right-margin {
  padding-right: 24px;
}

.jsoneditor-contextmenu .jsoneditor-separator {
  border-top: 1px solid #e5e5e5;
  height: 0;
  margin-top: 5px;
  padding-top: 5px;
}

.jsoneditor-contextmenu button.jsoneditor-remove .jsoneditor-icon {
  background-position: -24px 0;
}

.jsoneditor-contextmenu button.jsoneditor-append .jsoneditor-icon, .jsoneditor-contextmenu button.jsoneditor-insert .jsoneditor-icon {
  background-position: 0 0;
}

.jsoneditor-contextmenu button.jsoneditor-duplicate .jsoneditor-icon {
  background-position: -48px 0;
}

.jsoneditor-contextmenu button.jsoneditor-sort-asc .jsoneditor-icon {
  background-position: -168px 0;
}

.jsoneditor-contextmenu button.jsoneditor-sort-desc .jsoneditor-icon {
  background-position: -192px 0;
}

.jsoneditor-contextmenu button.jsoneditor-transform .jsoneditor-icon {
  background-position: -216px 0;
}

.jsoneditor-contextmenu button.jsoneditor-extract .jsoneditor-icon {
  background-position: 0 -24px;
}

.jsoneditor-contextmenu button.jsoneditor-type-string .jsoneditor-icon {
  background-position: -144px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-auto .jsoneditor-icon {
  background-position: -120px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-object .jsoneditor-icon {
  background-position: -72px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-array .jsoneditor-icon {
  background-position: -96px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-modes .jsoneditor-icon {
  background-image: none;
  width: 6px;
}

.jsoneditor-contextmenu ul, .jsoneditor-contextmenu li {
  box-sizing: content-box;
  position: relative;
}

.jsoneditor-contextmenu .jsoneditor-menu button:hover, .jsoneditor-contextmenu .jsoneditor-menu button:focus {
  color: #1a1a1a;
  background-color: #f5f5f5;
  outline: none;
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected, .jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover, .jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus {
  color: #fff;
  background-color: #ee422e;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button:hover, .jsoneditor-contextmenu .jsoneditor-menu li ul li button:focus {
  background-color: #f5f5f5;
}

.jsoneditor-modal {
  color: #4d4d4d;
  max-width: 95%;
  line-height: 1.3em;
  box-shadow: 2px 2px 12px #8080804d;
  border-radius: 2px !important;
  padding: 45px 15px 15px !important;
}

.jsoneditor-modal.jsoneditor-modal-transform {
  width: 600px !important;
}

.jsoneditor-modal .pico-modal-header {
  box-sizing: border-box;
  color: #fff;
  background: #3883fa;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  font-family: arial, sans-serif;
  font-size: 11pt;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.jsoneditor-modal table {
  width: 100%;
}

.jsoneditor-modal table td {
  padding: 3px 0;
}

.jsoneditor-modal table td.jsoneditor-modal-input {
  text-align: right;
  white-space: nowrap;
  padding-right: 0;
}

.jsoneditor-modal table td.jsoneditor-modal-actions {
  padding-top: 15px;
}

.jsoneditor-modal table th {
  vertical-align: middle;
}

.jsoneditor-modal p:first-child {
  margin-top: 0;
}

.jsoneditor-modal a {
  color: #3883fa;
}

.jsoneditor-modal .jsoneditor-jmespath-block {
  margin-bottom: 10px;
}

.jsoneditor-modal .pico-close {
  color: #fff;
  background: none !important;
  font-size: 24px !important;
  top: 7px !important;
  right: 7px !important;
}

.jsoneditor-modal input {
  padding: 4px;
}

.jsoneditor-modal input[type="text"] {
  cursor: inherit;
}

.jsoneditor-modal input[disabled] {
  color: gray;
  background: #d3d3d3;
}

.jsoneditor-modal .jsoneditor-select-wrapper {
  display: inline-block;
  position: relative;
}

.jsoneditor-modal .jsoneditor-select-wrapper:after {
  content: "";
  pointer-events: none;
  border-top: 6px solid #666;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 14px;
  right: 8px;
}

.jsoneditor-modal select {
  appearance: none;
  text-indent: 0;
  text-overflow: "";
  min-width: 180px;
  max-width: 350px;
  padding: 3px 24px 3px 10px;
  font-size: 14px;
  line-height: 1.5em;
}

.jsoneditor-modal select::-ms-expand {
  display: none;
}

.jsoneditor-modal .jsoneditor-button-group input {
  border-left-style: none;
  border-radius: 0;
  margin: 0;
  padding: 4px 10px;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-first {
  border-left-style: solid;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.jsoneditor-modal .jsoneditor-transform-preview {
  background: #f5f5f5;
  height: 200px;
}

.jsoneditor-modal .jsoneditor-transform-preview.jsoneditor-error {
  color: #ee422e;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard {
  border-radius: 3px;
  width: 100%;
  padding: 0;
  line-height: 1.2em;
}

.jsoneditor-modal .jsoneditor-jmespath-label {
  color: #1e90ff;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-table {
  border-collapse: collapse;
  width: 100%;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-label {
  margin: 4px 0 2px;
  font-style: italic;
}

.jsoneditor-modal .jsoneditor-inline {
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  position: relative;
}

.jsoneditor-modal .jsoneditor-inline:not(:last-child) {
  padding-right: 2px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter {
  flex-wrap: wrap;
  display: flex;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-field {
  width: 180px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-relation {
  width: 100px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-value {
  flex: 1;
  min-width: 180px;
}

.jsoneditor-modal .jsoneditor-jmespath-sort-field {
  width: 170px;
}

.jsoneditor-modal .jsoneditor-jmespath-sort-order {
  width: 150px;
}

.jsoneditor-modal .jsoneditor-jmespath-select-fields {
  width: 100%;
}

.jsoneditor-modal .selectr-selected {
  border-color: #d3d3d3;
  padding: 4px 28px 4px 8px;
}

.jsoneditor-modal .selectr-selected .selectr-tag {
  background-color: #3883fa;
  border-radius: 5px;
}

.jsoneditor-modal table th, .jsoneditor-modal table td {
  text-align: left;
  vertical-align: middle;
  color: #4d4d4d;
  border-spacing: 0;
  border-collapse: collapse;
  font-weight: normal;
}

.jsoneditor-modal select, .jsoneditor-modal textarea, .jsoneditor-modal input, .jsoneditor-modal input[type="text"], .jsoneditor-modal input[type="text"]:focus, .jsoneditor-modal #query {
  color: #4d4d4d;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  padding: 4px;
}

.jsoneditor-modal textarea, .jsoneditor-modal #query {
  border-radius: unset;
}

.jsoneditor-modal, .jsoneditor-modal table td, .jsoneditor-modal table th, .jsoneditor-modal select, .jsoneditor-modal option, .jsoneditor-modal textarea, .jsoneditor-modal input, .jsoneditor-modal input[type="text"], .jsoneditor-modal #query {
  font-family: arial, sans-serif;
  font-size: 10.5pt;
}

.jsoneditor-modal #query, .jsoneditor-modal .jsoneditor-transform-preview {
  box-sizing: border-box;
  width: 100%;
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
  font-size: 14px;
}

.jsoneditor-modal input[type="button"], .jsoneditor-modal input[type="submit"] {
  background: #f5f5f5;
  padding: 4px 20px;
}

.jsoneditor-modal select, .jsoneditor-modal input {
  cursor: pointer;
}

.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-asc input.jsoneditor-button-asc, .jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
  color: #fff;
  background: #3883fa;
  border-color: #3883fa;
}

.jsoneditor {
  color: #1a1a1a;
  box-sizing: border-box;
  border: thin solid #3883fa;
  width: 100%;
  height: 100%;
  padding: 0;
  line-height: 100%;
  position: relative;
}

div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor-readonly, div.jsoneditor-default {
  word-wrap: break-word;
  float: left;
  border: 1px solid #0000;
  min-width: 32px;
  min-height: 16px;
  margin: 1px;
  padding: 2px;
  line-height: 16px;
}

div.jsoneditor-field p, div.jsoneditor-value p {
  margin: 0;
}

div.jsoneditor-value {
  word-break: break-word;
}

div.jsoneditor-value.jsoneditor-empty:after {
  content: "value";
}

div.jsoneditor-value.jsoneditor-string {
  color: #006000;
}

div.jsoneditor-value.jsoneditor-number {
  color: #ee422e;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00;
}

div.jsoneditor-value.jsoneditor-null {
  color: #004ed0;
}

div.jsoneditor-value.jsoneditor-color-value, div.jsoneditor-value.jsoneditor-invalid {
  color: #1a1a1a;
}

div.jsoneditor-readonly {
  color: gray;
  min-width: 16px;
}

div.jsoneditor-empty {
  border-style: dashed;
  border-color: #d3d3d3;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-empty:after {
  content: "field";
}

div.jsoneditor td {
  vertical-align: top;
}

div.jsoneditor td.jsoneditor-separator {
  vertical-align: top;
  color: gray;
  padding: 3px 0;
}

div.jsoneditor td.jsoneditor-tree {
  vertical-align: top;
}

div.jsoneditor.busy pre.jsoneditor-preview {
  color: gray;
  background: #f5f5f5;
}

div.jsoneditor.busy div.jsoneditor-busy {
  display: inherit;
}

div.jsoneditor code.jsoneditor-preview {
  background: none;
}

div.jsoneditor.jsoneditor-mode-preview pre.jsoneditor-preview {
  box-sizing: border-box;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 2px;
  overflow: auto;
}

div.jsoneditor-default {
  color: gray;
  padding-left: 10px;
}

div.jsoneditor-tree {
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

div.jsoneditor-tree button.jsoneditor-button {
  cursor: pointer;
  background-color: #0000;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  border: none;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
}

div.jsoneditor-tree button.jsoneditor-button:focus {
  background-color: #f5f5f5;
  outline: 1px solid #e5e5e5;
}

div.jsoneditor-tree button.jsoneditor-collapsed {
  background-position: 0 -48px;
}

div.jsoneditor-tree button.jsoneditor-expanded {
  background-position: 0 -72px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button {
  background-position: -48px -72px;
}

div.jsoneditor-tree button.jsoneditor-invisible {
  visibility: hidden;
  background: none;
}

div.jsoneditor-tree button.jsoneditor-dragarea {
  cursor: move;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: -72px -72px;
}

div.jsoneditor-tree :focus {
  outline: none;
}

div.jsoneditor-tree div.jsoneditor-show-more {
  color: gray;
  background-color: #e5e5e5;
  border-radius: 3px;
  margin: 2px 0;
  padding: 3px 4px;
  font-family: arial, sans-serif;
  font-size: 14px;
  display: inline-block;
}

div.jsoneditor-tree div.jsoneditor-show-more a {
  color: gray;
  display: inline-block;
}

div.jsoneditor-tree div.jsoneditor-color {
  cursor: pointer;
  border: 1px solid gray;
  width: 12px;
  height: 12px;
  margin: 4px;
  display: inline-block;
}

div.jsoneditor-tree div.jsoneditor-color.jsoneditor-color-readonly {
  cursor: inherit;
}

div.jsoneditor-tree div.jsoneditor-date {
  color: #fff;
  background: #a1a1a1;
  border-radius: 3px;
  margin: 0 3px;
  padding: 3px;
  font-family: arial, sans-serif;
  display: inline-block;
}

div.jsoneditor-tree table.jsoneditor-tree {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

div.jsoneditor-tree .jsoneditor-button {
  display: block;
}

div.jsoneditor-tree .jsoneditor-button.jsoneditor-schema-error {
  background-color: #0000;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: -168px -48px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  padding: 0;
}

div.jsoneditor-outer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: static;
}

div.jsoneditor-outer.has-nav-bar {
  margin-top: -26px;
  padding-top: 26px;
}

div.jsoneditor-outer.has-nav-bar.has-main-menu-bar {
  margin-top: -61px;
  padding-top: 61px;
}

div.jsoneditor-outer.has-status-bar {
  margin-bottom: -26px;
  padding-bottom: 26px;
}

div.jsoneditor-outer.has-main-menu-bar {
  margin-top: -35px;
  padding-top: 35px;
}

div.jsoneditor-busy {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  display: none;
  position: absolute;
  top: 15%;
  left: 0;
}

div.jsoneditor-busy span {
  background-color: #ffffab;
  border: 1px solid #fe0;
  border-radius: 3px;
  padding: 5px 15px;
  box-shadow: 0 0 5px #0006;
}

div.jsoneditor-field.jsoneditor-empty:after, div.jsoneditor-value.jsoneditor-empty:after {
  pointer-events: none;
  color: #d3d3d3;
  font-size: 8pt;
}

div.jsoneditor-value.jsoneditor-url, a.jsoneditor-value.jsoneditor-url {
  color: #006000;
  text-decoration: underline;
}

a.jsoneditor-value.jsoneditor-url {
  margin: 2px;
  padding: 2px;
  display: inline-block;
}

a.jsoneditor-value.jsoneditor-url:hover, a.jsoneditor-value.jsoneditor-url:focus {
  color: #ee422e;
}

div.jsoneditor-field[contenteditable="true"]:focus, div.jsoneditor-field[contenteditable="true"]:hover, div.jsoneditor-value[contenteditable="true"]:focus, div.jsoneditor-value[contenteditable="true"]:hover, div.jsoneditor-field.jsoneditor-highlight, div.jsoneditor-value.jsoneditor-highlight {
  background-color: #ffffab;
  border: 1px solid #fe0;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-highlight-active, div.jsoneditor-field.jsoneditor-highlight-active:focus, div.jsoneditor-field.jsoneditor-highlight-active:hover, div.jsoneditor-value.jsoneditor-highlight-active, div.jsoneditor-value.jsoneditor-highlight-active:focus, div.jsoneditor-value.jsoneditor-highlight-active:hover {
  background-color: #fe0;
  border: 1px solid #ffc700;
  border-radius: 2px;
}

div.jsoneditor-value.jsoneditor-object, div.jsoneditor-value.jsoneditor-array {
  min-width: 16px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button:hover, div.jsoneditor-tree button.jsoneditor-contextmenu-button:focus, div.jsoneditor-tree button.jsoneditor-contextmenu-button.jsoneditor-selected, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button {
  background-position: -48px -48px;
}

div.jsoneditor-tree div.jsoneditor-show-more a:hover, div.jsoneditor-tree div.jsoneditor-show-more a:focus {
  color: #ee422e;
}

textarea.jsoneditor-text, .ace-jsoneditor {
  min-height: 150px;
}

textarea.jsoneditor-text.ace_editor, .ace-jsoneditor.ace_editor {
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
}

textarea.jsoneditor-text {
  box-sizing: border-box;
  resize: none;
  background-color: #fff;
  border: none;
  outline-width: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

tr.jsoneditor-highlight, tr.jsoneditor-selected {
  background-color: #d3d3d3;
}

tr.jsoneditor-selected button.jsoneditor-dragarea, tr.jsoneditor-selected button.jsoneditor-contextmenu-button {
  visibility: hidden;
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button {
  visibility: visible;
}

div.jsoneditor-tree button.jsoneditor-dragarea:hover, div.jsoneditor-tree button.jsoneditor-dragarea:focus, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
  background-position: -72px -48px;
}

div.jsoneditor tr, div.jsoneditor th, div.jsoneditor td {
  margin: 0;
  padding: 0;
}

div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor td, div.jsoneditor th, div.jsoneditor textarea, pre.jsoneditor-preview, .jsoneditor-schema-error, .jsoneditor-popover {
  color: #1a1a1a;
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
  font-size: 14px;
}

.jsoneditor-schema-error {
  cursor: default;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.jsoneditor-popover {
  color: #fff;
  cursor: auto;
  background-color: #4c4c4c;
  border-radius: 3px;
  width: 200px;
  padding: 7px 10px;
  position: absolute;
  box-shadow: 0 0 5px #0006;
}

.jsoneditor-popover.jsoneditor-above {
  bottom: 32px;
  left: -98px;
}

.jsoneditor-popover.jsoneditor-above:before {
  border-top: 7px solid #4c4c4c;
  bottom: -7px;
}

.jsoneditor-popover.jsoneditor-below {
  top: 32px;
  left: -98px;
}

.jsoneditor-popover.jsoneditor-below:before {
  border-bottom: 7px solid #4c4c4c;
  top: -7px;
}

.jsoneditor-popover.jsoneditor-left {
  top: -7px;
  right: 32px;
}

.jsoneditor-popover.jsoneditor-left:before {
  content: "";
  top: 19px;
  right: -14px;
  left: inherit;
  margin-left: inherit;
  border-top: 7px solid #0000;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #4c4c4c;
  margin-top: -7px;
  position: absolute;
}

.jsoneditor-popover.jsoneditor-right {
  top: -7px;
  left: 32px;
}

.jsoneditor-popover.jsoneditor-right:before {
  content: "";
  margin-left: inherit;
  border-top: 7px solid #0000;
  border-bottom: 7px solid #0000;
  border-right: 7px solid #4c4c4c;
  margin-top: -7px;
  position: absolute;
  top: 19px;
  left: -14px;
}

.jsoneditor-popover:before {
  content: "";
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  margin-left: -7px;
  display: block;
  position: absolute;
  left: 50%;
}

.jsoneditor-text-errors tr.jump-to-line:hover {
  cursor: pointer;
  text-decoration: underline;
}

.jsoneditor-schema-error:hover .jsoneditor-popover, .jsoneditor-schema-error:focus .jsoneditor-popover {
  animation: .3s linear fade-in, .3s linear move-up;
  display: block;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.jsoneditor .jsoneditor-validation-errors-container {
  max-height: 130px;
  overflow-y: auto;
}

.jsoneditor .jsoneditor-validation-errors {
  width: 100%;
  overflow: hidden;
}

.jsoneditor .jsoneditor-additional-errors {
  color: gray;
  background-color: #ebebeb;
  border-radius: 8px;
  margin: auto;
  padding: 7px 15px;
  position: absolute;
  bottom: 31px;
  left: calc(50% - 92px);
}

.jsoneditor .jsoneditor-additional-errors.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.jsoneditor .jsoneditor-additional-errors.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.jsoneditor .jsoneditor-text-errors {
  border-collapse: collapse;
  border-top: 1px solid #ffc700;
  width: 100%;
}

.jsoneditor .jsoneditor-text-errors td {
  vertical-align: middle;
  padding: 3px 6px;
}

.jsoneditor .jsoneditor-text-errors td pre {
  white-space: pre-wrap;
  margin: 0;
}

.jsoneditor .jsoneditor-text-errors tr {
  background-color: #ffffab;
}

.jsoneditor .jsoneditor-text-errors tr.parse-error {
  background-color: #ee2e2e70;
}

.jsoneditor-text-errors .jsoneditor-schema-error {
  cursor: pointer;
  border: none;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  padding: 0;
}

.jsoneditor-text-errors tr .jsoneditor-schema-error {
  background-color: #0000;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: -168px -48px;
}

.jsoneditor-text-errors tr.parse-error .jsoneditor-schema-error {
  background-color: #0000;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: -25px 0;
}

.jsoneditor-anchor {
  cursor: pointer;
}

.jsoneditor-anchor .picker_wrapper.popup.popup_bottom {
  top: 28px;
  left: -10px;
}

.fadein {
  animation: .3s fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.jsoneditor-modal input[type="search"].selectr-input {
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  width: calc(100% - 4px);
  margin: 2px;
  padding: 4px;
}

.jsoneditor-modal button.selectr-input-clear {
  right: 8px;
}

.jsoneditor-menu {
  box-sizing: border-box;
  color: #fff;
  background-color: #3883fa;
  border-bottom: 1px solid #3883fa;
  width: 100%;
  height: 35px;
  margin: 0;
  padding: 2px;
}

.jsoneditor-menu > button, .jsoneditor-menu > .jsoneditor-modes > button {
  color: #fff;
  opacity: .8;
  float: left;
  background-color: #0000;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  border: 1px solid #0000;
  border-radius: 2px;
  width: 26px;
  height: 26px;
  margin: 2px;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.jsoneditor-menu > button:hover, .jsoneditor-menu > .jsoneditor-modes > button:hover {
  background-color: #fff3;
  border: 1px solid #fff6;
}

.jsoneditor-menu > button:focus, .jsoneditor-menu > button:active, .jsoneditor-menu > .jsoneditor-modes > button:focus, .jsoneditor-menu > .jsoneditor-modes > button:active {
  background-color: #ffffff4d;
}

.jsoneditor-menu > button:disabled, .jsoneditor-menu > .jsoneditor-modes > button:disabled {
  opacity: .5;
  background-color: #0000;
  border: none;
}

.jsoneditor-menu > button.jsoneditor-collapse-all {
  background-position: 0 -96px;
}

.jsoneditor-menu > button.jsoneditor-expand-all {
  background-position: 0 -120px;
}

.jsoneditor-menu > button.jsoneditor-sort {
  background-position: -120px -96px;
}

.jsoneditor-menu > button.jsoneditor-transform {
  background-position: -144px -96px;
}

.jsoneditor.jsoneditor-mode-view > .jsoneditor-menu > button.jsoneditor-sort, .jsoneditor.jsoneditor-mode-form > .jsoneditor-menu > button.jsoneditor-sort, .jsoneditor.jsoneditor-mode-view > .jsoneditor-menu > button.jsoneditor-transform, .jsoneditor.jsoneditor-mode-form > .jsoneditor-menu > button.jsoneditor-transform {
  display: none;
}

.jsoneditor-menu > button.jsoneditor-undo {
  background-position: -24px -96px;
}

.jsoneditor-menu > button.jsoneditor-undo:disabled {
  background-position: -24px -120px;
}

.jsoneditor-menu > button.jsoneditor-redo {
  background-position: -48px -96px;
}

.jsoneditor-menu > button.jsoneditor-redo:disabled {
  background-position: -48px -120px;
}

.jsoneditor-menu > button.jsoneditor-compact {
  background-position: -72px -96px;
}

.jsoneditor-menu > button.jsoneditor-format {
  background-position: -72px -120px;
}

.jsoneditor-menu > button.jsoneditor-repair {
  background-position: -96px -96px;
}

.jsoneditor-menu > .jsoneditor-modes {
  float: left;
  display: inline-block;
}

.jsoneditor-menu > .jsoneditor-modes > button {
  background-image: none;
  width: auto;
  padding-left: 6px;
  padding-right: 6px;
}

.jsoneditor-menu > button.jsoneditor-separator, .jsoneditor-menu > .jsoneditor-modes > button.jsoneditor-separator {
  margin-left: 10px;
}

.jsoneditor-menu a {
  color: #fff;
  opacity: .8;
  vertical-align: middle;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.jsoneditor-menu a:hover {
  opacity: 1;
}

.jsoneditor-menu a.jsoneditor-poweredBy {
  padding: 10px;
  font-size: 8pt;
  position: absolute;
  top: 0;
  right: 0;
}

.jsoneditor-navigation-bar {
  box-sizing: border-box;
  color: gray;
  background-color: #ebebeb;
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
  height: 26px;
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
}

.jsoneditor-search {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: arial, sans-serif;
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px;
}

.jsoneditor-search input {
  color: #1a1a1a;
  border: none;
  outline: none;
  width: 120px;
  margin: 1px;
  font-family: arial, sans-serif;
  line-height: 20px;
}

.jsoneditor-search button {
  vertical-align: top;
  background: url("jsoneditor-icons.912c84a2.svg");
  border: none;
  width: 16px;
  height: 24px;
  margin: 0;
  padding: 0;
}

.jsoneditor-search button:hover {
  background-color: #0000;
}

.jsoneditor-search button.jsoneditor-refresh {
  background-position: -99px -73px;
  width: 18px;
}

.jsoneditor-search button.jsoneditor-next {
  cursor: pointer;
  background-position: -124px -73px;
}

.jsoneditor-search button.jsoneditor-next:hover {
  background-position: -124px -49px;
}

.jsoneditor-search button.jsoneditor-previous {
  cursor: pointer;
  background-position: -148px -73px;
  margin-right: 2px;
}

.jsoneditor-search button.jsoneditor-previous:hover {
  background-position: -148px -49px;
}

.jsoneditor-results {
  color: #fff;
  padding-right: 5px;
  font-family: arial, sans-serif;
  line-height: 26px;
}

.jsoneditor-frame {
  background-color: #fff;
  border: 1px solid #0000;
  margin: 0;
  padding: 0 2px;
}

.jsoneditor-statusbar {
  color: gray;
  box-sizing: border-box;
  background-color: #ebebeb;
  border-top: 1px solid #d3d3d3;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
}

.jsoneditor-statusbar > .jsoneditor-curserinfo-val {
  margin-right: 12px;
}

.jsoneditor-statusbar > .jsoneditor-curserinfo-count {
  margin-left: 4px;
}

.jsoneditor-statusbar > .jsoneditor-validation-error-icon {
  float: right;
  cursor: pointer;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: -168px -48px;
  width: 24px;
  height: 24px;
  margin-top: 1px;
  padding: 0;
}

.jsoneditor-statusbar > .jsoneditor-validation-error-count {
  float: right;
  cursor: pointer;
  margin: 0 4px 0 0;
}

.jsoneditor-statusbar > .jsoneditor-parse-error-icon {
  float: right;
  background-image: url("jsoneditor-icons.912c84a2.svg");
  background-position: -25px 0;
  width: 24px;
  height: 24px;
  margin: 1px;
  padding: 0;
}

.jsoneditor-statusbar .jsoneditor-array-info a {
  color: inherit;
}

div.jsoneditor-statusbar > .jsoneditor-curserinfo-label, div.jsoneditor-statusbar > .jsoneditor-size-info {
  margin: 0 4px;
}

.jsoneditor-treepath {
  white-space: nowrap;
  outline: none;
  padding: 0 5px;
  overflow: hidden;
}

.jsoneditor-treepath.show-all {
  word-wrap: break-word;
  white-space: normal;
  z-index: 1;
  background-color: #ebebeb;
  position: absolute;
  box-shadow: 2px 2px 12px #8080804d;
}

.jsoneditor-treepath.show-all span.jsoneditor-treepath-show-all-btn {
  display: none;
}

.jsoneditor-treepath div.jsoneditor-contextmenu-root {
  position: absolute;
  left: 0;
}

.jsoneditor-treepath .jsoneditor-treepath-show-all-btn {
  cursor: pointer;
  background-color: #ebebeb;
  height: 20px;
  padding: 0 3px;
  position: absolute;
  left: 0;
}

.jsoneditor-treepath .jsoneditor-treepath-element {
  margin: 1px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.jsoneditor-treepath .jsoneditor-treepath-seperator {
  margin: 2px;
  font-family: arial, sans-serif;
  font-size: 9pt;
}

.jsoneditor-treepath span.jsoneditor-treepath-element:hover, .jsoneditor-treepath span.jsoneditor-treepath-seperator:hover {
  cursor: pointer;
  text-decoration: underline;
}

.selectr-container {
  position: relative;
}

.selectr-container li {
  list-style: none;
}

.selectr-hidden {
  clip: rect(0px, 0px, 0px, 0px);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.selectr-visible {
  opacity: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.selectr-desktop.multiple .selectr-visible {
  display: none;
}

.selectr-desktop.multiple.native-open .selectr-visible {
  opacity: 1;
  height: auto;
  display: block;
  top: 100%;
  min-height: 200px !important;
}

.selectr-container.multiple.selectr-mobile .selectr-selected {
  z-index: 0;
}

.selectr-selected {
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 100%;
  padding: 7px 28px 7px 14px;
  position: relative;
}

.selectr-selected:before {
  content: "";
  border: 4px solid #0000;
  border-top-color: #6c7a86;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: rotate(0)translate3d(0, -50%, 0);
}

.selectr-container.open .selectr-selected:before, .selectr-container.native-open .selectr-selected:before {
  border: 4px solid #0000;
  border-top-width: 0;
  border-bottom-color: #6c7a86;
}

.selectr-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: none;
  overflow: hidden;
}

.selectr-placeholder {
  color: #6c7a86;
}

.selectr-tags {
  white-space: normal;
  margin: 0;
  padding: 0;
}

.has-selected .selectr-tags {
  margin: 0 0 -2px;
}

.selectr-tag {
  float: left;
  cursor: default;
  color: #fff;
  background: #acb7bf;
  border: none;
  border-radius: 10px;
  margin: 0 2px 2px 0;
  padding: 2px 25px 2px 8px;
  list-style: none;
  position: relative;
}

.selectr-container.multiple.has-selected .selectr-selected {
  padding: 5px 28px 5px 5px;
}

.selectr-options-container {
  z-index: 10000;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #999;
  border-top: 0 solid #0000;
  border-radius: 0 0 3px 3px;
  width: 100%;
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
}

.selectr-container.open .selectr-options-container {
  display: block;
}

.selectr-input-container {
  display: none;
  position: relative;
}

.selectr-clear, .selectr-input-clear, .selectr-tag-remove {
  cursor: pointer;
  z-index: 11;
  background-color: #0000;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translate3d(0, -50%, 0);
}

.selectr-clear, .selectr-input-clear {
  display: none;
}

.selectr-container.has-selected .selectr-clear, .selectr-input-container.active .selectr-input-clear {
  display: block;
}

.selectr-selected .selectr-tag-remove {
  right: 2px;
}

.selectr-clear:before, .selectr-clear:after, .selectr-input-clear:before, .selectr-input-clear:after, .selectr-tag-remove:before, .selectr-tag-remove:after {
  content: " ";
  background-color: #6c7a86;
  width: 2px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 9px;
}

.selectr-tag-remove:before, .selectr-tag-remove:after {
  background-color: #fff;
  width: 3px;
  height: 12px;
  top: 4px;
}

.selectr-clear:before, .selectr-input-clear:before, .selectr-tag-remove:before {
  transform: rotate(45deg);
}

.selectr-clear:after, .selectr-input-clear:after, .selectr-tag-remove:after {
  transform: rotate(-45deg);
}

.selectr-input-container.active, .selectr-input-container.active .selectr-clear {
  display: block;
}

.selectr-input {
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 3px;
  width: calc(100% - 30px);
  margin: 10px 15px;
  padding: 7px 30px 7px 9px;
  top: 5px;
  left: 5px;
}

.selectr-notice {
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #999;
  border-radius: 0 0 3px 3px;
  width: 100%;
  padding: 8px 16px;
  display: none;
}

.selectr-container.notice .selectr-notice {
  display: block;
}

.selectr-container.notice .selectr-selected {
  border-radius: 3px 3px 0 0;
}

.selectr-options {
  max-height: 200px;
  margin: 0;
  padding: 0;
  display: none;
  position: relative;
  top: calc(100% + 2px);
  overflow-x: auto;
  overflow-y: scroll;
}

.selectr-container.open .selectr-options, .selectr-container.open .selectr-input-container, .selectr-container.notice .selectr-options-container {
  display: block;
}

.selectr-option {
  list-style: outside none none;
  cursor: pointer;
  padding: 5px 20px;
  font-weight: normal;
  display: block;
  position: relative;
}

.selectr-options.optgroups > .selectr-option {
  padding-left: 25px;
}

.selectr-optgroup {
  padding: 0;
  font-weight: bold;
}

.selectr-optgroup--label {
  margin-top: 10px;
  padding: 5px 15px;
  font-weight: bold;
}

.selectr-match {
  text-decoration: underline;
}

.selectr-option.selected {
  background-color: #ddd;
}

.selectr-option.active {
  color: #fff;
  background-color: #5897fb;
}

.selectr-option.disabled {
  opacity: .4;
}

.selectr-option.excluded {
  display: none;
}

.selectr-container.open .selectr-selected {
  border-color: #999 #999 #0000;
  border-radius: 3px 3px 0 0;
}

.selectr-container.open .selectr-selected:after {
  transform: rotate(180deg)translate3d(0, 50%, 0);
}

.selectr-disabled {
  opacity: .6;
}

.selectr-empty, .has-selected .selectr-placeholder {
  display: none;
}

.has-selected .selectr-label {
  display: block;
}

.taggable .selectr-selected {
  padding: 4px 28px 4px 4px;
}

.taggable .selectr-selected:after {
  content: " ";
  clear: both;
  display: table;
}

.taggable .selectr-label {
  width: auto;
}

.taggable .selectr-tags {
  float: left;
  display: block;
}

.taggable .selectr-placeholder {
  display: none;
}

.input-tag {
  float: left;
  width: auto;
  min-width: 90px;
}

.selectr-tag-input {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border: none;
  width: 100%;
  padding: 3px 10px;
}

.selectr-input-container.loading:after {
  content: "";
  -o-transform-origin: 50% 0 0;
  -ms-transform-origin: 50% 0 0;
  -moz-transform-origin: 50% 0 0;
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  border: 3px solid #ddd;
  border-top-color: #aaa;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: .5s linear infinite forwards selectr-spin;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate3d(0, -50%, 0);
}

@keyframes selectr-spin {
  0% {
    transform: rotate(0)translate3d(0, -50%, 0);
  }

  100% {
    transform: rotate(360deg)translate3d(0, -50%, 0);
  }
}

.selectr-container.open.inverted .selectr-selected {
  border-color: #0000 #999 #999;
  border-radius: 0 0 3px 3px;
}

.selectr-container.inverted .selectr-options-container {
  background-color: #fff;
  border-width: 1px 1px 0;
  border-color: #999 #999 #0000;
  border-radius: 3px 3px 0 0;
  top: auto;
  bottom: calc(100% - 1px);
}

.selectr-container ::-webkit-input-placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container ::placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container :-ms-input-placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container ::placeholder {
  color: #6c7a86;
  opacity: 1;
}

.rc-slider {
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
  border-radius: 6px;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  position: relative;
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
}

.rc-slider-rail {
  background-color: #e9e9e9;
  border-radius: 6px;
  width: 100%;
  height: 4px;
  position: absolute;
}

.rc-slider-track {
  background-color: #abe2fb;
  border-radius: 6px;
  height: 4px;
  position: absolute;
  left: 0;
}

.rc-slider-handle {
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  background-color: #fff;
  border: 2px solid #96dbfa;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  position: absolute;
}

.rc-slider-handle:focus {
  border-color: #57c5f7;
  outline: none;
  box-shadow: 0 0 0 5px #96dbfa;
}

.rc-slider-handle-click-focused:focus {
  box-shadow: unset;
  border-color: #96dbfa;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
}

.rc-slider-handle:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
}

.rc-slider-mark {
  width: 100%;
  font-size: 12px;
  position: absolute;
  top: 18px;
  left: 0;
}

.rc-slider-mark-text {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
  display: inline-block;
  position: absolute;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  background: none;
  width: 100%;
  height: 4px;
  position: absolute;
}

.rc-slider-dot {
  cursor: pointer;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  position: absolute;
  bottom: -2px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-dot-reverse {
  margin-left: 0;
  margin-right: -4px;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
  box-shadow: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ccc;
}

.rc-slider-disabled .rc-slider-mark-text, .rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  width: 4px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-track {
  width: 4px;
  bottom: 0;
  left: 5px;
}

.rc-slider-vertical .rc-slider-handle {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin-left: -5px;
}

.rc-slider-vertical .rc-slider-mark {
  height: 100%;
  top: 0;
  left: 18px;
}

.rc-slider-vertical .rc-slider-step {
  width: 4px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-dot {
  margin-bottom: -4px;
  left: 2px;
}

.rc-slider-vertical .rc-slider-dot:first-child, .rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear, .rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-play-state: paused;
  animation-fill-mode: both;
  display: block !important;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active, .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear {
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0);
  }
}

.rc-slider-tooltip {
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px;
}

.rc-slider-tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #6c6c6c;
  border-radius: 6px;
  min-width: 24px;
  height: 24px;
  padding: 6px 2px;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
  margin-left: -4px;
  bottom: 4px;
  left: 50%;
}

/*# sourceMappingURL=index.5492f1c1.css.map */
